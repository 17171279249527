import React from 'react';
import { StyledNavItem, StyledBaseLink, StyledRouterLink } from './Navbar.styled';

const NavItem = ({ label, link }) => {
  const isExternal = link.startsWith('http') || link.startsWith('/downloads');
  
  return (
    <StyledNavItem>
      {isExternal ? (
        <StyledBaseLink 
          href={link} 
          download={link.startsWith('/downloads')} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {label}
        </StyledBaseLink>
      ) : (
        <StyledRouterLink to={link}>
          {label}
        </StyledRouterLink>
      )}
    </StyledNavItem>
  );
};

export default NavItem;
