import React from 'react';

import '../styles/ImgSection.css'
import Button from './Button';


function ImgSection(props) {
    return (
        <section class="ImgSection">

            <div class="content">
                <h1>{props.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                <Button
                    button_text={props.button_text}
                    button_url={props.button_url}
                    button_status={props.button_status}
                />
            </div>
            <div class="image">
                <img alt="Person performing a parkour move, jumping off a wall in an urban environment" height="400" src={props.img} width="600" />
            </div>
        </section>
    );
}

export default ImgSection;
