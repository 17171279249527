// pages/HomePage.js
import React from 'react';

// <========ButtonS========> \\
import Button from '../components/Button';

// <=========CARDS=========> \\
import CardSection from '../components/cards/CardSection';
import CardLargeImg from '../components/cards/CardLargeImg';
import CardMediumImg from '../components/cards/CardMediumImg';
import CardInfo from '../components/cards/CardInfo';

import Schedule from '../components/Schedule';
import ScheduleContent from '../components/ScheduleContent';
import ScheduleItem from '../components/ScheduleItem';

import TitleSection from '../components/TitleSection';

import Banner from '../components/banner';

function LessenPage() {
  return (
    <main>
      <Banner
        src="images/banner.jpg"
        title='lessen'
      />

      <CardSection>
        <CardInfo
          title="lessen"
          note="Schrijf je in voor wekelijkse lessen voor leeftijden vanaf 6 jaar."
          button_text="schrijf je in"
          button_url="https://app.twizzit.com/v2/public/form/8dedc68e7ab210542836a3f8a20bb534"
          button_status="enabled"
        />
        <CardInfo
          title="proeflessen"
          note="Plan 2 gratis proeflessen voor leeftijden vanaf 6 jaar."
          button_text="boek je proefles"
          button_url="https://app.twizzit.com/v2/public/form/719f446a5a0d676e13cfa349beaeead6"
          button_status="enabled"

        />
      </CardSection>

      <TitleSection title="planning"/>

      <Schedule>
        <ScheduleContent title="brugge">
          <ScheduleItem leeftijd="6-8 & 9-12" wanneer="donderdag 17u-18u30" prijs="230" />
          <ScheduleItem leeftijd="12+" wanneer="donderdag 18u30-20u" prijs="230" />
          <ScheduleItem leeftijd="18+" wanneer="maandag 19u30-21u30" prijs="230" />
          <ScheduleItem leeftijd="9-11" wanneer="zaterdag 16u30-18u" prijs="230" />
          <ScheduleItem leeftijd="12+" wanneer="zaterdag 18u-20u" prijs="230" />
        </ScheduleContent>

        <ScheduleContent title="kortrijk">
          <ScheduleItem leeftijd="9-11" wanneer="zaterdag 9u-10u30" prijs="230" />
          <ScheduleItem leeftijd="12+" wanneer="zaterdag 12u15-14u" prijs="230" />
          <ScheduleItem leeftijd="18+" wanneer="zaterdag 10u30-12u15" prijs="230" />
          <ScheduleItem leeftijd="6-8" wanneer="zondag 10u-11u" prijs="230" />
          <ScheduleItem leeftijd="9-11" wanneer="zondag 11u-12u30" prijs="230" />
        </ScheduleContent>

        <ScheduleContent title="veurne">
          <ScheduleItem leeftijd="6-8" wanneer="donderdag 17u-18u" prijs="230" />
          <ScheduleItem leeftijd="9-11" wanneer="donderdag 18u-19u30" prijs="230" />
          <ScheduleItem leeftijd="12+" wanneer="donderdag 19u30-21u" prijs="230" />
        </ScheduleContent>

        <ScheduleContent title="blankenberge">
          <ScheduleItem leeftijd="6-8" wanneer="zondag 9u-10u" prijs="230" />
          <ScheduleItem leeftijd="9-11" wanneer="zondag 10u-11u30" prijs="230" />
          <ScheduleItem leeftijd="12+" wanneer="zondag 11u30-13u" prijs="230" />
        </ScheduleContent>
      </Schedule>
    </main>
  );
}

export default LessenPage;