import React from 'react';
import '../../styles/CardInfo.css'
import Button from '../Button';
import { LiaCalendarDaySolid } from "react-icons/lia";
import { LiaClockSolid } from "react-icons/lia";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { LiaMapMarkerSolid } from "react-icons/lia";
import { LiaStickyNoteSolid } from "react-icons/lia";
import { LiaTagSolid } from "react-icons/lia";






function CardInfo(props) {
  return (
    <div className='CardInfo'>
      <div className='content'>
        <h1> {props.title} </h1>
        <ul>
        <li>{props.date ? (<><LiaCalendarDaySolid /> {props.date}</>) : ('')}</li>
        <li>{props.time ? (<><LiaClockSolid /> {props.time}</>) : ('')}</li>
        <li>{props.age ? (<><LiaBirthdayCakeSolid /> {props.age}</>) : ('')}</li>
        <li><a href={props.location_url} target='blank'>{props.location ? (<><LiaMapMarkerSolid /> {props.location}</>) : ('')}</a></li>
        <li>{props.price ? (<><LiaTagSolid /> {/^\d/.test(props.price) ? `€${props.price}` : props.price}</>) : ('')}</li>
        <li>{props.note ? (<><LiaStickyNoteSolid /> {props.note}</>) : ('')}</li>
        </ul>
      </div>
      <Button 
      button_text={props.button_text}
      button_url={props.button_url}
      button_status={props.button_status}
      />
    </div>
  );
}

export default CardInfo;