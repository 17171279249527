import React from 'react';
import { LiaInstagram } from "react-icons/lia";
import { LiaFacebookF } from "react-icons/lia";
import { LiaYoutube } from "react-icons/lia";
import '../styles/footer.css';

const Footer = () => {
    return (
        <footer class="footer">
            <img src="images/logo.svg" alt="" width="100px" />
            <div className='media'>
                <a href="">
                    <LiaInstagram />
                </a>
                <a href="">
                    <LiaFacebookF />
                </a>
                <a href="">
                    <LiaYoutube />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
