import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import './styles/navbar.css';

// <==========NAV==========> \\
import Nav from './components/navbar/Nav';
import Dropdown from './components/navbar/Dropdown';
import NavItem from './components/navbar/NavItem';

// <=========FOOTER=========> \\
import Footer from './components/footer';

// <=========PAGES=========> \\
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import LessenPage from './pages/LessenPage';
import KampenPage from './pages/KampenPage';
import ContactPage from './pages/ContactPage';
import CoachEuropePage from './pages/Coach_EuropePage';
import VrijeTrainingenPage from './pages/VrijeTrainingenPage';

function Layout() {
  const location = useLocation();

  useEffect(() => {
    // Change the Button color for a specific page
    if (location.pathname === '/coach_europe') {
      document.documentElement.style.setProperty('--main-color', '#0c6e7b');
      document.documentElement.style.setProperty('--active-color', '#44939e');
    } else {
      document.documentElement.style.setProperty('--main-color', '#00a053');
      document.documentElement.style.setProperty('--active-color', '#006735');
    }
  }, [location.pathname]);

  return (
    <>
      <Nav>
        <NavItem label="Home" link="/" />
        <Dropdown label="Aanbod">
          <NavItem label="Lessen" link="/lessen" />
          <NavItem label="Kampen" link="/kampen" />
          <NavItem label="Vrije&nbsp;Trainingen" link="/vrije_trainingen" />
          <NavItem label="Verjaardagen" link="https://parkouruni.be/collections/verjaardagen" />
          <NavItem label="Workshops" link="https://parkouruni.be/collections/workshops" />
        </Dropdown>
        <Dropdown label="Evenementen">
          <NavItem label="Coach&nbsp;Europe" link="/coach_europe" />
        </Dropdown>
        <Dropdown label="Legal">
          <NavItem label="Terms" link="/downloads/exceptionMovementTerms.pdf" />
          <NavItem label="Policy" link="/downloads/exceptionMovementPolicy.pdf" />
        </Dropdown>
      </Nav>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/kampen" element={<KampenPage />} />
        <Route path="/lessen" element={<LessenPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/coach_europe" element={<CoachEuropePage />} />
        <Route path="/vrije_trainingen" element={<VrijeTrainingenPage />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
