// pages/HomePage.js
import React from 'react';

// <========ButtonS========> \\
import Button from '../components/Button';

// <=========CARDS=========> \\
import CardSection from '../components/cards/CardSection';
import CardLargeImg from '../components/cards/CardLargeImg';
import CardMediumImg from '../components/cards/CardMediumImg';
import CardInfo from '../components/cards/CardInfo';


import Banner from '../components/banner';

function ContactPage() {
    return (
        <main>
        <Banner
          src="images/banner.jpg"
          title='contact'
        />
        <CardSection>
          <CardLargeImg
            src="images/img1.jpg"
            title="evenementen"
          />
        </CardSection>

        <CardSection>
          <CardInfo
            title="wanyi kamp"
            date="22/07/2025"
            time="8:30 am"
            note="testing"
            age="16"
            location="bloso brugge"
            location_url="https://www.google.nl/maps/dir//Sport+Vlaanderen+Brugge,+Nijverheidsstraat+112,+8310+Brugge,+België/@51.181369,3.1091142,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c350b9fa373b87:0x472d31056fc8ce39!2m2!1d3.2418239!2d51.206431!3e0?hl=nl&entry=ttu"
            button_text="meer info"
            button_url=""
          />
        </CardSection>

        <CardSection>
          <CardMediumImg
            button_text="Bestel hier"
            src="images/img1.jpg"
          />
        </CardSection>
      </main>
    );
}

export default ContactPage;