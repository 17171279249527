import React from 'react';

function ScheduleRow(props) {
  return (
    <tr>
      <td>{props.wanneer}</td>
      <td>{props.leeftijd}</td>
      <td>&euro;{props.prijs}</td>
    </tr>

  );
}

export default ScheduleRow;