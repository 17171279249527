import React from 'react';
import '../styles/Schedule.css'

function Schedule({ children }) {
  return (
    <section className='schedule'>
      {children}
    </section>
  );
}

export default Schedule;
