import React from 'react';
import '../../styles/CardSection.css'



function CardSection({children}) {
  return (
    <section className='CardSection'>
        {children}
    </section>
  );
}

export default CardSection;