import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/CardLargeImg.css';

function CardLargeImg({ link, img, title}) {
  const Content = (
    <div className="CardLargeImg">
      <img src={img} alt={title} />
      <div className="CardLargeImg-overlay"></div>
      <h1>{title}</h1>
    </div>
  );

  const isExternal = link.startsWith('http');

  return isExternal ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {Content}
    </a>
  ) : (
    <Link to={link}>
      {Content}
    </Link>
  );
}

export default CardLargeImg;
