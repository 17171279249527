// pages/HomePage.js
import React from 'react';

// <========ButtonS========> \\
import Button from '../components/Button';

// <=========CARDS=========> \\
import CardSection from '../components/cards/CardSection';
import CardLargeImg from '../components/cards/CardLargeImg';
import CardMediumImg from '../components/cards/CardMediumImg';
import CardInfo from '../components/cards/CardInfo';

import Banner from '../components/banner';

import ImgSection from '../components/ImgSection';

import TitleSection from '../components/TitleSection';

import SplitSection from '../components/SplitSection';
import SplitItemImg from '../components/SplitItemImg';
import SplitItemList from '../components/SplitItemList';
import SplitItemText from '../components/SplitItemText';



function Coach_EuropePage() {
  return (
    <main>
      <Banner
        src="images/bannerCe.jpg"
      />
      <SplitSection>
        <SplitItemImg
          title="buy your ticket here"
          button_status="enabled"
          button_text="register"
          button_url="https://app.twizzit.com/v2/public/form/35555fd21f6a3e52b5e2ab304a51d819"
          img="images/imgCe1.jpg"
        />
        <SplitItemImg
          title="host your workshop"
          button_status="disabled"
          button_text="not yet available"
          button_url=""
          img="images/imgCe2.jpg"
        />
        <SplitItemImg
          title="host a warmup game"
          button_status="disabled"
          button_text="not yet available"
          button_url=""
          img="images/imgCe3.jpg"
        />
      </SplitSection>
      <TitleSection title="what's included?" />
      <SplitSection>
        <div>
          <h5>all-in ticket</h5>
          <SplitItemList
            price="Coming soon"
          />
        </div>
        <div>
          <h5>weekend ticket</h5>
          <SplitItemList
            price="Coming soon"
          />
        </div>
        <div>
          <h5>day ticket</h5>
          <SplitItemList
            price="Coming soon"
          />
        </div>
      </SplitSection>
      <TitleSection title="optional fee" />
        <ImgSection img="images/imgCe4.jpg" text="We strive to organize this event efficiently and make it accessible.

We mainly charge only the event expenses, with minimal compensation for organizers and coaches.

You can support the event by paying an optional fee of €30, if you wish and are able to.

This fee is entirely optional and will help cover travel and other costs for those making Coach Europe possible." button_status="enabled" button_text="support coach europe"/>
      <TitleSection title="why you should join?" />
      <SplitSection>
        <SplitItemText title="connect" text="Connect with passionate coaches."/>
        <SplitItemText title="share" text="Exchange expertise & experience."/>
        <SplitItemText title="be inspired" text="Discuss diffrent challenges.

"/>
      </SplitSection>






    </main>
  );
}

export default Coach_EuropePage;