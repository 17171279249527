import React from 'react';
import '../../styles/CardMediumImg.css'
import Button from '../Button';

function CardMediumImg(props) {
  return (
    <div className='CardMediumImg'>
      <div className='img-container'>
        <img src={props.img} alt="" />
      </div>
      <Button
        button_text={props.button_text}
        button_url={props.button_url}
        button_status={props.button_status}
      />
    </div>
  );
}

export default CardMediumImg;