import React from 'react';

function SplitItemText(props) {
  return (
    <div className='SplitItemText'>
      <h5>{props.title}</h5>
      <p>{props.text}</p>
    </div>

  );
}

export default SplitItemText;