import React from 'react';

function SplitItemImg(props) {
  return (
    <li>
      {isNaN(Number(props.price)) ? props.price : `${props.price} EUR`} 
      {props.text ? ` - ${props.text}` : ''}
      <br />
      {props.date_time ? `(${props.date_time})` : ''}
    </li>
  );
}

export default SplitItemImg;
