import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavBar = styled.nav`
  background: var(--nav-color);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 40px;
  z-index: 10;
`;

export const NavList = styled.ul`
    display: flex;
    flex-direction: row;

  @media (max-width: 875px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: var(--nav-color);
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    top: 45px;
    width: 200px;
    transition: right 0.3s ease;
  }
`;

export const NavButton = styled.button`
  background: var(--main-color);
  border: none;
  display: none;
  font-size: 16px;
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;

  @media (max-width: 875px) {
    display: block;
  }

  &:hover {
    background: var(--active-color);
  }
`;

export const StyledNavItem = styled.li`
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  color: inherit;



  @media (max-width: 875px) {
    padding: 10px 20px;

  }

    &:hover {
    color: var(--active-color);
  }
`;

export const StyledNavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;


  &:hover {
    color: var(--active-color);
  }
`;

export const StyledBaseLink = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    color: var(--active-color);
  }
`;

export const StyledRouterLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    color: var(--active-color);
  }
`;


export const StyledDropdown = styled.li`
  position: relative;

  &:hover ul {
    display: flex;
  }
`;

export const DropdownList = styled.ul`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 0;
  background: var(--nav-color);
  list-style: none;
  color: #9a9a9a;

  @media (max-width: 875px) {
    position: static;
    padding: 0px 20px;
  }
`;

export const DropdownToggle = styled.div`
  cursor: pointer;
  display: block;
  padding: 10px 20px;
  cursor: default;

  svg {
    margin-left: 5px;
  }
`;
