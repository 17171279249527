import React from 'react';
import '../styles/SplitSection.css'




function SplitSection({children}) {
  return (
    <section class="triple-section">
        {children}
    </section>
  );
}

export default SplitSection;