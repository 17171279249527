import React from 'react';

import Button from './Button';


function SplitItemImg(props) {
  return (
    <div>
      <h5>{props.title}</h5>
      <img src={props.img} alt="" loading="lazy" />
      <Button
        button_status={props.button_status}
        button_url={props.button_url}
        button_text={props.button_text}
      />
    </div>
  );
}

export default SplitItemImg;