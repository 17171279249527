import React from 'react';
import '../styles/Button.css';
import { LiaAngleRightSolid } from "react-icons/lia";

function Button(props) {
  const { button_status, button_url, button_text } = props;

  // Don't render anything if button_status is blank
  if (!button_status) return null;

  return (
    <button
      className={`Button-link ${button_status === "disabled" ? "disabled" : ""}`}
      onClick={button_status === "enabled" ? () => window.open(button_url, "_blank") : undefined}
      disabled={button_status === "disabled"} // Adds native disabled attribute
    >
      <p>{button_text}</p>
      <p><LiaAngleRightSolid /></p>
    </button>
  );
}

export default Button;
