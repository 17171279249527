// pages/HomePage.js
import React from 'react';

// <========ButtonS========> \\
import Button from '../components/Button';

// <=========CARDS=========> \\
import CardSection from '../components/cards/CardSection';
import CardLargeImg from '../components/cards/CardLargeImg';
import CardMediumImg from '../components/cards/CardMediumImg';
import CardInfo from '../components/cards/CardInfo';

import ImgSection from '../components/ImgSection';

import Banner from '../components/banner';

import TitleSection from '../components/TitleSection';


function HomePage() {
  return (
    <main>
      <Banner
        src="images/banner.jpg"
        title='exception movement'
      />
      <ImgSection
        title="Waarom exception movement?"
        text="Bij Exception Movement is parkour <b>meer dan alleen bewegen</b>. Wij bouwen <b>een warme community</b> waar iedereen zich thuis voelt en zichzelf kan uitdagen. <b>Respect, veiligheid en plezier</b> staan bij ons centraal. We bieden een omgeving waarin je <b>op jouw eigen tempo en niveau</b> je eigen <b>grenzen kunt verleggen</b>."
        img="images/banner.jpg"
      />
      <TitleSection title="aanbod" />
      <CardSection>
        <CardLargeImg
          title="lessen"
          img="images/img1.jpg"
          link="/lessen"
        />
        <CardLargeImg
          title="kampen"
          img="images/img2.jpg"
          link="/kampen"
        />
        <CardLargeImg
          title="vrije trainingen"
          img="images/img3.jpg"
          link="/vrije_trainingen"
        />
        <CardLargeImg
          title="workshops"
          img="images/img1.jpg"
          link="https://parkouruni.be/collections/workshops"
        />
        <CardLargeImg
          title="verjaardagen"
          img="images/img2.jpg"
          link="https://parkouruni.be/collections/verjaardagen"
        />
      </CardSection>

      <TitleSection title="merchandise" />
      <CardSection>
        <CardMediumImg
          button_text="Bestel hier"
          img="images/imgMerch1.jpg"
        />
        <CardMediumImg
          button_text="Bestel hier"
          img="images/imgMerch2.jpg"
        />
        <CardMediumImg
          button_text="Bestel hier"
          button_status="disabled"
          img="images/imgMerch3.jpg"
        />
      </CardSection>

    </main>
  );
}

export default HomePage;