import React from 'react';
import { StyledDropdown, DropdownList, DropdownToggle } from './Navbar.styled';

const Dropdown = ({ label, children }) => (
  <StyledDropdown>
    <DropdownToggle>{label} ▾</DropdownToggle>
    <DropdownList>{children}</DropdownList>
  </StyledDropdown>
);

export default Dropdown;
