import React, { useState } from 'react';
import { NavBar, NavList, NavButton } from './Navbar.styled';

const Nav = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  return (
    <NavBar>
      <NavList isOpen={isMenuOpen}>{children}</NavList>
      <NavButton onClick={toggleMenu}>{isMenuOpen ? '✕' : '☰'}</NavButton>
    </NavBar>
  );
};

export default Nav;
