import React from 'react';
import '../styles/TitleSection.css'


function TitleSection(props) {
    return (
        <section className='TitleSection'>
            <h1>{props.title}</h1>
        </section>
    );
}

export default TitleSection;
